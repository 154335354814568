<template>
  <b-modal
    v-model="isModalVisible"
    size="md"
    dialog-class="mt-md-5 p-0"
    content-class="p-2"
    footer-class="border-top-0"
    header-class="border-bottom-0"
    @ok="submit"
    @cancel="cancel"
  >
    <template v-slot:modal-header>
      <h5>Select service for client: {{}}</h5>
    </template>
    <template v-slot:default>
      <p>here place the services</p>
      <div>
        <button v-for="service in serviceList" :key="service.id" style="width: 100%;" class="no-decoration-btn mt-1"
            @click="selectService(service)"
        >
          <b-card no-body class="p-3 mb-2"
                :class="`${service === selectedService?'selected':''}`"
          >
            
            <b-row class="no-gutters align-items-center">
              <b-col class="col-auto mr-3">
                <img :src="clientTypeImg" class="user__icon" />
              </b-col>
              <b-col>
                <div class="font-weight-bold">
                  {{ service.name }}
                </div>
              </b-col>
              <b-col class=""
                >{{ $t("page.services.numAnalysis") }}
                {{
                  service.numAnalysis !== 0 ? service.numAnalysis : "Unlimited"
                }}</b-col
              >
              
            </b-row>
          </b-card>
        </button>
      </div>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button
        variant="white"
        class="mr-3 btn-blue-border justify-content-center align-items-center bold"
        @click="cancel"
        >{{ $t("button.cancel") }}</b-button
      >
      <b-button
        variant="dark"
        class="justify-content-center align-items-center bold"
        @click="ok"
        >{{ $t("button.ok") }}</b-button
      >
    </template>
  </b-modal>
</template>

<script>
export default {
  props: {
    modalStatus: {
      type: Boolean,
      required: true,
    },
  },
  data(){
    return {
        selectedService:{}
    }
  },
  components: {
  },
  computed: {
    serviceList() {
      return this.$store.getters["servicePredict/getServiceList"];
    },
    isEmptyList() {
      return this.serviceList.length === 0;
    },
    clientTypeImg() {
      if (this.isClientMultiuser) {
        return require("./../../assets/icons/users.svg");
      } else {
        return require("./../../assets/icons/user.svg");
      }
    },
    isModalVisible: {
      get() {
        return this.modalStatus;
      },
      set(modalStatus) {
        this.$emit("setModalStatus", modalStatus);
      },
    },
  },
  methods: {
    submit() {
      // create folder
      this.$emit("submit",this.selectedService._id);
      this.selectedService={}
    },
    cancel() {
      this.selectedService={}
    },
    selectService(service){
        this.selectedService=service
    },
  },
};
</script>

<style>
.user__icon {
  width: 20px;
  filter: invert(0%) sepia(85%) saturate(7500%) hue-rotate(173deg)
    brightness(87%) contrast(113%);
}
.no-decoration-btn {
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
  text-decoration: none;
}
.no-decoration-btn:hover,
.no-decoration-btn:focus,
.no-decoration-btn:active {
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
  text-decoration: none;
  color: inherit; /* Ensures the text color doesn't change on hover */
  outline: none; /* Removes the outline on focus */
}
.selected{
    background-color: #0331517b;
}
</style>
