<template>
    <div>
      <base-page-title :titleText="pageTitle" />
      <b-row class="no-gutters p-3">
        <b-button class="ml-auto" @click="createPredictClientAccount">
          {{ $t("button.addPredictClient") }}
        </b-button>
      </b-row>
      <b-col class="col-12 col-md-8 p-3 mx-auto">
        <client-list />
      </b-col>
    </div>
  </template>
  
  <script>
  import ClientList from "./../components/Client/ClientList";
  export default {
    components: {
      ClientList
    },
    data() {
      return {
        pageTitle: "page.predict.title"
      };
    },
    methods: {
      createPredictClientAccount() {
        this.$router.push({ name: "CreatePredictClientAccount" });
      }
    }
  };
  </script>
  
  <style scoped></style>
  