<template>
  <div>
    <div v-for="client in clientList" :key="client.id">
      <client-list-item :client="client" v-if="showClient(client)"/>
    </div>
    <div v-if="isEmptyList">
      <h5 class="text-center">Empty list</h5>
    </div>
  </div>
</template>

<script>
import ClientListItem from "./ClientListItem";
import Utils from "../../Utils";
export default {
  props:{
    hidePredict:{
      type:Boolean,
      required:false,
      default:false
    }
  },
  components: {
    ClientListItem
  },
  computed: {
    clientList() {
      return this.$store.getters["clients/getClientList"];
    },
    isEmptyList() {
      return this.clientList.length === 0;
    },
  },
  methods:{
    showClient(client){
      if(!this.hidePredict)return true
      if(client.clientType === Utils.CLIENT_TYPE.PREDICT)return false;
      return true
    }
  }
};
</script>

<style scoped></style>
